import { graphql, useStaticQuery } from "gatsby"

import Layout from '../layouts'
import PageHeader from '../components/page/header'
import React from 'react'
import Seo from '../components/seo'
import Text from '../components/text'
import Workshops from '../components/workshops'

const Therapy = () => {
  const { pageHeaderImage, workshops=[] } = useStaticQuery(
    graphql`
      {
        pageHeaderImage: imageSharp(resize: {originalName: {eq: "seminare.jpg"}}) {
          gatsbyImageData(
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1000
          )
        }

        workshops: allCmsSeminar {
          edges {
            node {
              id
              name: title
              date: startsAtDisplay
              location
              level
              bookingStatus
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo
        title='Seminare'
        currentUrl='/seminare'
      />
      <PageHeader
        headline='Seminare'
        copy='Stets spannende Themen und aktuelle Impulse sowie Praxisnähe und viel Leidenschaft. Die Seminare unterstützen Sie im selbstständigen Umgang mit Ihren Problemen, Schmerzen und Ihrem Gesundheitsmanagement.'
        image={pageHeaderImage}
      />
      <Text text={`
Die Seminare sind so konzipiert, dass sie den Teilnehmern aktuelles Wissen vermitteln, welches sie zum Fördern und Bewahren ihrer Gesundheit brauchen und bei ihnen die gewünschte Einstellungs- und Verhaltensänderung auslösen. Das theoretische Wissen ist klar verständlich und kann sofort in den Alltag selbstständig integriert werden. Ein ausführliches Skript lässt darüber hinaus keine Inhalte vergessen.
      `}/>
      <Text text={`
In den Live-Seminaren lehre ich die theoretischen Grundlagen ausgewählter Aspekte mit gezielter praktischer Anwendung und direktem Übertrag für Ihr Training und Ihren Alltag.
Ich vermittle eindrucksvoll und verständlich die Zusammenhänge von Bewegung, Schmerzen, Ernährung und Lebensqualität und biete die passenden Konzepte für langfristiges Wohlbefinden. Die Inhalte basieren auf aktueller Forschung aus dem Bereich der Neurowissenschaften, Sportwissenschaft, Therapie sowie Erfahrung aus dem Kraft- und Beweglichkeitstraining.

Die 90-minütigen Seminare finden in meiner Sport- und Therapiepraxis statt und sind in der Teilnehmeranzahl beschränkt. Somit wird ein persönlicher Wissensaustausch gewährleistet sowie die Hygienevorschriften eingehalten.
      `}/>
      <Workshops
        headline="Übersicht der kommenden Seminare"
        workshops={workshops.edges.map(node => node.node)}
      />
    </Layout>
  )
}

export default Therapy
